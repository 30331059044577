:root {
  --grid-gap: var(--p);
  --grid-cell: var(--c3);

  @media (--media-lg) {
    --grid-cell: var(--c4);
  }
}

.auto-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-cell), 1fr));
  gap: var(--grid-gap);
}
