@font-face {
  font-family: "Satoshi-Variable";
  src: local("Satoshi-Variable"),
    url(/static/assets/fonts/Satoshi-Variable.woff2) format("woff2");
  font-weight: 300 900;
  font-display: swap;
}

html {
  font-size: clamp(1rem, 1vw, 1.5rem);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Satoshi-Variable", Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1.4;
}

a:where([class]) {
  text-decoration: none;
  color: inherit;
}

.prose {
  & > * {
    max-width: var(--w-prose);
  }

  h1,
  h2,
  h3 {
    margin: var(--margin-l) auto var(--margin) 0;
    max-width: var(--w-content);
    line-height: 1.2;
  }

  p,
  .button,
  figure {
    margin-bottom: 1rem;
  }

  .lead {
    font-size: 1.5rem;
    margin-block: var(--spacing) var(--margin);
  }

  ul,
  ol {
    margin: 0 var(--margin-xl) var(--margin);

    @media (--media-sm-only) {
      margin: 0 auto var(--margin);
    }

    li + li {
      margin-top: 1rem;
    }
  }
}

.prose a:not([class]),
.caption a {
  color: inherit;
  font-style: italic;
  font-weight: bold;
}

.caption,
small,
.small,
figure .caption p {
  font-size: 1rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.75rem;
}
