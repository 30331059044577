header {
  z-index: var(--z-header);
}

.header-container,
.menu-header-container {
  position: sticky;
  display: flex;
  align-items: center;
}

.header-container {
  position: relative;
  justify-content: space-between;
  gap: var(--grid-gap);
  height: 6rem;

  > .separator {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    @media (--media-md) {
      width: calc(100% - (2 * var(--p)));
      left: unset;
    }
    border-bottom: 1px solid var(--fg);
    z-index: var(--z-logo);
  }
}

.menu-header-container {
  justify-content: right;
}

.menu-is-open,
.modal-is-open {
  overflow: hidden;
  .header-container .separator {
    border-color: var(--green);
  }
}

.mobile-menu {
  display: none;

  position: absolute;
  top: -1.8rem;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: calc(100vh + 2rem);
  overflow-y: auto;

  padding-top: var(--margin-m);
  padding-bottom: var(--margin-xl);

  background-color: var(--neutral);

  color: var(--green);
  --fg: var(--green);

  z-index: var(--z-mobile-menu);

  > .container {
    overflow: hidden;
  }

  .nav-toggle {
    margin-left: auto;
    .button {
      --font-color: var(--white);
      --accent-color: var(--green);
    }
  }

  h2 {
    margin-top: var(--margin);
  }
}

.menu-is-open .mobile-menu {
  display: unset;
  opacity: 0;
  animation: fade-in 0.3s ease-in-out both;
}

.menu-is-closed .mobile-menu {
  display: unset;
  opacity: 1;
  animation: fade-out 0.3s ease-in-out both;
}

.menu-is-closed.menu-is-hidden .mobile-menu {
  display: none;
}

.language-nav {
  display: flex;
  gap: var(--gap);
  padding-bottom: var(--margin);
  .nav-link {
    font-weight: 500;
  }
}

.mobile-nav,
.submenu {
  font-size: 1.33rem;
  display: flex;
  flex-direction: column;
}

.nav-link {
  color: inherit;
  padding: 0.5em 0;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--font-color-hover);
  }
}

.logo-container {
  font-size: 1.5rem;
  font-weight: bold;

  span {
    position: absolute;
    z-index: var(--z-logo);
    top: 2rem;
  }
}

.menu-is-open .logo-container span {
  color: var(--green);
}

.menu-wrap,
.language-nav {
  margin-top: var(--spacing);
}

.menu-wrap {
  display: flex;
  transition: transform 0.2s ease-in-out;
  nav {
    min-width: calc(var(--c3) - var(--margin));
  }
}

.submenu-wrap {
  flex: 1 0 calc(100vw - var(--margin));

  .invisible {
    opacity: 0;
  }

  .visible {
    opacity: 1;
  }
}

.submenu,
.mobile-nav {
  padding-right: var(--margin);

  transition: opacity 0.2s ease-in-out;
}

.submenu-backlink {
  font-weight: 500;
  border-bottom: 1px solid var(--green);
  padding-bottom: calc(2 * var(--spacing-s));
  .icon {
    margin-right: var(--spacing-s);
  }
}

@media (--media-lg) {
  .menu-wrap {
    margin-top: var(--margin);
    transform: none !important;
  }
  .submenu {
    padding-left: var(--spacing-s);
    border-left: 1px solid var(--green);
  }
  .submenu-backlink {
    display: none;
  }
  .submenu-wrap {
    flex: 1 0 calc(var(--c3) - var(--margin));
  }
}
