footer {
  --fg: var(--black);
  --bg: var(--white);
  padding-top: var(--margin);
  padding-bottom: var(--margin-l);
  background-color: var(--bg);
  color: var(--fg);
  z-index: var(--z-footer);

  border-top: 1px solid var(--green);
}

.footer-container {
  display: flex;
  flex-direction: column;

  a.active {
    font-weight: bold;
  }
}

.supporters {
  --grid-gap: var(--spacing);
  --grid-cell: 10rem;
  margin-bottom: var(--margin-l);
  h2 {
    font-size: 1.5rem;
    margin-bottom: var(--spacing);
  }
  a {
    margin: auto;
    min-height: 5rem;
    display: flex;
  }
  img {
    margin: auto;
    width: 100%;
    max-width: 10rem;
  }
}

.footer-nav {
  border-top: 1px solid var(--green);
  font-size: 1.33rem;
  padding-block: var(--margin-m);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  .nav-link {
    margin-right: auto;
  }
}

.social-media-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--margin);
}

.footer-nav,
.social-media-nav {
  .nav-link {
    font-size: 1.25rem;
    color: var(--green);
    &:hover {
      color: var(--font-color-hover);
    }
  }
}

@media (--media-md) {
  .supporters {
    --grid-cell: 12.5rem;
    img {
      max-width: 12.5rem;
    }
  }
}
