.form {
  font-size: 1.125rem;
  .fields {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
  .field {
    position: relative;
    input,
    textarea {
      width: 100%;
      border: 1px solid var(--black);
      padding: 0.3em 0.9em;
      background-color: var(--sun);
      &::placeholder {
        color: var(--gray-opaque);
      }
    }
    label {
      opacity: 0;
      position: absolute;
      font-size: 0.75rem;
      top: -1.2rem;
      left: 0;
      transition: all 0.3s ease-in-out;
    }
    input:not(:placeholder-shown) + label {
      opacity: 0.66;
    }
  }
}
