.g .cell.active {
  display: none;
}

@media (--media-md) {
  .g .cell.active {
    display: flex;
  }
}

.gt {
  margin-top: var(--margin);
  --grid-gap: 0;

  .cell {
    padding-block: var(--p);
    display: flex;
    flex-direction: column;
    background-color: var(--accent-color);
    /* gap: var(--spacing); */
    background-image: url(/static/assets/images/pattern.png);

    h2 {
      margin-block: 0;

      @media (--media-sm-only) {
        font-size: 1.125rem;
      }
    }

    p {
      margin-bottom: var(--spacing);
    }

    .button {
      --size: 1.25rem;
      margin-top: auto;
      margin-right: auto;
      margin-bottom: 0px;

      @media (--media-md) {
        --size: 2rem;
      }
    }
  }
}

.container.g-detail {
  display: grid;
  grid-template-areas: "a" "b" "c";

  > .left {
    grid-area: c;
    grid-column-end: 2;
    margin-inline: 0;
    &.title {
      grid-area: a;
      h1 {
        margin-bottom: 0;
      }
    }
  }

  > .right {
    grid-area: b;
    margin: auto;
  }
}

@media (--media-sm-only) {
  .gt {
    grid-template-columns: 1fr 1fr;
    .cell {
      padding: calc(var(--p) / 2) !important;
    }
  }
}

@media (--media-md) {
  .gt {
    --grid-gap: var(--gap);
    margin-bottom: var(--margin);
  }
}
@media (--media-lg) {
  .container.g-detail {
    grid-template-areas: "a a b" "c c b" "c c b";
    gap: var(--grid-gap);

    h1 {
      margin-bottom: var(--spacing);
    }
  }
}
