.counseling-info {
  margin-block: var(--margin);
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  @media (--media-lg) {
    margin-block: var(--margin-xxl);
  }
}

.info-container {
  width: 100%;
  padding: 0.5rem 1rem 1rem;
  border: 2px solid var(--font-color);
  /* border-radius: 2rem; */

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.info-container--important {
  background-color: var(--font-color);
  color: var(--accent-color);
}

.info-container__inner {
  display: flex;
  flex-direction: column;
  margin-block: 1rem;

  &:not(:last-of-type) {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--font-color);
  }
}

.info-container__inner,
.info-container__header {
  p {
    margin-bottom: 1rem;
  }
  .button {
    margin-top: auto;
    margin-right: auto;
  }
}

@media (--media-md) {
  .info-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);

    &.half {
      width: calc(50% - var(--p) / 2);
    }

    &.center {
      text-align: center;
    }
  }
  .info-container__header {
    flex: 1 0 100%;
  }
  .info-container__inner {
    flex: 1 1 calc(33% - 1rem);
    padding-right: 1rem;
    &:not(:last-of-type) {
      padding-bottom: 0;
      border-bottom: 0;
      border-right: 1px solid var(--font-color);
    }
  }
}
details summary {
  width: 100%;
  .icon {
    transition: transform 0.2s ease-in-out;
  }
}
details[open] summary .icon {
  transform: rotate(180deg);
}

/* Webkit fix */
details > summary::-webkit-details-marker {
  display: none;
}

.region-buttons {
  display: flex;
  flex-direction: column;
  margin-block: var(--spacing-s);
  margin-left: var(--spacing);
  gap: var(--spacing-s);
}
