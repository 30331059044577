@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animation__wrap {
  display: flex;
  video {
    -webkit-mask-image: url(/static/assets/animations/mask.svg);
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-image: url(/static/assets/animations/mask.svg);
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    width: 600px;
    max-width: 100%;
    margin-inline: auto;
  }
}

/* @keyframes lightning-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes lightning-rotate2 {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(-270deg);
  }
} */

/* @keyframes box-animation {
  from {
    max-width: 0px;
    max-height: 0px;
    opacity: 0;
  }
  to {
    max-width: 300vw;
    max-height: 300vw;
    opacity: 1;
  }
} */

/* .gt .cell .button {
  position: relative;
} */

/* .box-animation {
  position: absolute;
  background: linear-gradient(
    120deg,
    var(--accent-color) 33%,
    var(--black) 120%
  );

  max-width: 1px;
  max-height: 1px;

  transform: translate(-50%, 0%);
  transform-origin: 50%;

  width: 300vw;
  height: 300vh;

  z-index: var(--z-box-animation);
  filter: blur(2rem);

  &.animate {
    animation: box-animation 300ms linear backwards;
  }
} */
/*
.animation__wrap {
  position: relative;
  z-index: 2;

  img {
  }
}

.lightning-rotate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    right: 0;
    bottom: 0;
    width: 70%;
    height: 0.4rem;
    transform-origin: center;
    background: var(--white);
    filter: blur(1rem);
    animation: lightning-rotate2 4.3s linear infinite both;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 15%;
    right: 0;
    bottom: 0;
    width: 75%;
    height: 0.4rem;
    transform-origin: center;
    background: var(--white);
    filter: blur(1.5rem);
    animation: lightning-rotate 3.7s linear infinite both;
    z-index: -1;
  }
} */
