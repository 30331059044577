.button,
.btn {
  --fg: var(--white);
  --bg: var(--transparent);
  --inv-fg: var(--accent-color);
  --inv-bg: var(--white);
  --important-fg: var(--white);
  --important-bg: var(--accent-color);

  font-size: 1em;
  font-weight: bold;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  outline: none;
  border: var(--border) var(--fg);
  text-decoration: none;

  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  border-radius: 0.5em;
  padding: 0.3em 0.8em;
  cursor: pointer;

  color: var(--fg);

  background-color: var(--bg);
  transition: background-color 0.2s ease-in-out;

  &:is(a) {
    color: var(--fg);
  }
}

a.button,
a.btn {
  color: var(--fg);
}

.button--control {
  --fg: var(--accent-color);
  --bg: var(--font-color);
  --inv-fg: var(--font-color);
  --inv-bg: var(--accent-color);
  border-radius: 50%;
  padding: 0.3em;
}

.button--inverted,
.button.active,
.button:hover,
.button:focus-visible {
  --fg: var(--inv-fg);
  --bg: var(--inv-bg);
}

.button--inverted {
  &.active,
  &:hover,
  &:focus-visible {
    --fg: var(--fg);
    --bg: var(--bg);
    border: var(--border) var(--font-color);
    .icon {
      fill: var(--font-color);
    }
  }
}

.button--clean {
  background: none;
  color: unset;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
}

.button--important {
  --fg: var(--important-fg);
  --bg: var(--important-bg);
  border: var(--border) var(--bg);
}

.button--important {
  &.active,
  &:hover,
  &:focus-visible {
    border: var(--border) var(--fg);
    .icon {
      fill: var(--fg);
    }
  }
}
