:root {
  --white: #ffffff;
  --black: #08080d;
  --gray: #404060;
  --gray-opaque: hsla(0, 0, 8%, 50%);
  --transparent: rgba(0, 0, 0, 0);

  --green: hsla(181, 56%, 29%, 1);
  --blue-dark: hsla(227, 56%, 29%, 1);
  --gold: hsla(50, 33%, 47%, 1);
  --red: hsla(0, 48%, 48%, 1);
  --petrol: hsla(194, 50%, 40%, 1);
  --plum: hsla(313, 46%, 31%, 1);
  --blue: hsla(209, 62%, 40%, 1);

  --neutral: var(--white);

  --accent-color: var(--neutral);
  --font-color: var(--green);
  --font-color-hover: var(--black);

  /* --shadow: 1px 1px 3px var(--black); */
}

.accent-green {
  --accent-color: var(--green);
}
.accent-blue-dark {
  --accent-color: var(--blue-dark);
}
.accent-gold {
  --accent-color: var(--gold);
}
.accent-red {
  --accent-color: var(--red);
}
.accent-petrol {
  --accent-color: var(--petrol);
}
.accent-plum {
  --accent-color: var(--plum);
}
.accent-blue {
  --accent-color: var(--blue);
}
.accent-blue {
  --accent-neutral: var(--neutral);
}

html {
  --bg: var(--accent-color);
  --fg: var(--font-color);
  background: linear-gradient(
    120deg,
    var(--accent-color) 33%,
    var(--black) 120%
  );
  color: var(--font-color);
}

body {
  position: relative;
  opacity: 0;
  animation: fade-in 0.3s 0.3s ease-in forwards;
  background-image: url(/static/assets/images/pattern.png);
}

span.heading-gradient {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1rem;
    right: -1rem;
    bottom: 0;
    background-color: hsla(1, 0%, 100%, 0.2);
    /* mix-blend-mode: soft-light; does not perform well on some colors */
    filter: blur(1.5rem);
    pointer-events: none;
  }
}

.f3cc {
  --f3cc-background: #cbcbcb;
  --f3cc-foreground: var(--black);
  --f3cc-button-background: var(--neutral);
  --f3cc-accept-background: var(--accent-color);
  --f3cc-button-foreground: var(--black);
  --f3cc-accept-foreground: var(--neutral);
}
