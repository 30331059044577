.container {
  max-width: var(--w);
  padding-inline: var(--p);

  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

main {
  flex: 1 1 100%;
}

main > .container.prose:last-child {
  margin-bottom: var(--margin-m);
}
main > .container.prose:first-child {
  margin-top: var(--margin-m);
}

.container > .container {
  padding-inline: 0;
}

.container {
  &.sm--full {
    padding-inline: 0;
    & > .container {
      padding-inline: var(--p);
    }
    @media (--media-md) {
      padding-inline: var(--p);
    }
  }
}
