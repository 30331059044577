.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
  &.skip-link:focus-visible {
    height: auto;
    width: auto;
  }
}

.hidden,
.hide {
  display: none;
}

.flex {
  display: flex;
}
