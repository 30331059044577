:root {
  --c: 5.5rem;
  --c2: 12.5rem;
  --c3: 19.5rem;
  --c4: 26.5rem;
  --c5: 33.5rem;
  --c6: 40.5rem;
  --c7: 47.5rem;
  --c8: 54.5rem;
  --c9: 61.5rem;
  --c10: 68.5rem;
  --c11: 75.5rem;
  --c12: 82.5rem;
  --c-outer: 85.51rem;

  --w: var(--c-outer);
  --w-content: var(--c10);
  --w-prose: var(--c8);
  --w-max: 94.5rem;

  --p: 1.5rem;
  --gap: 1.5rem;

  --spacing-s: 0.5rem;
  --spacing: 2rem;

  --margin: 3rem;
  --margin-m: 3rem;
  --margin-l: 4rem;
  --margin-xl: 6rem;
  --margin-xxl: 9rem;

  /* @media (--media-md) {
    --margin-m: 6rem;
    --margin-l: 10rem;
    --margin-xl: 12.5rem;
  } */

  --size: 2em;
  --icon-color: var(--black);

  --errors-fg: var(--red);

  --interact-size: max(16px, 1.125rem);
  --screening-animation-time: 0.5s;

  --border: 0.125em solid;

  --z-header: 20;
  --z-footer: 19;
  --z-mobile-menu: 90;
  --z-logo: 91;
  --z-box-animation: 99;
}
