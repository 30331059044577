.icon {
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;

  fill: var(--fg);
}

.inline-icon {
  display: inline-flex;
  align-items: center;

  --size: 1em;
  /* transform: translateX(0.33em); */
  &.right .icon {
    margin-left: auto;
  }
}
